<template>
  <component
    :is="getMdcTag(tag)"
    :id="componentId"
    class="page-section"
    :class="[{ 'full-width': fullWidth }]"
    data-testid="page-section"
    :style="{
      alignItems: mappedVerticalAlign,
      backgroundColor: fullWidth && !!backgroundColor ? backgroundColor : undefined,
      borderRadius: fullWidth ? borderRadius : undefined,
      ...(fullWidth && backgroundImage ? backgroundImageMapping : {}),
      padding,
      border: fullWidth && !!border ? border : undefined,
    }"
  >
    <div
      class="section-container"
      data-testid="section-container"
      :style="{
        backgroundColor: !fullWidth && !!backgroundColor ? backgroundColor : undefined,
        ...(!fullWidth && backgroundImage ? backgroundImageMapping : {}),
        /** fixes ts incompatible error */
        borderRadius: fullWidth ? undefined : borderRadius as string | undefined,
        maxWidth,
        border: !fullWidth && !!border ? border : undefined,
      }"
    >
      <div
        class="section-content"
        data-testid="section-content"
        :style="{
          color: color || undefined,
          textAlign,
          display: horizontalAlign ? 'flex' : 'block',
          flexDirection: horizontalAlign ? (!!$slots.title ? 'column' : 'row' ) : undefined,
          alignItems: !!$slots.title ? mappedHorizontalAlign : undefined,
          justifyContent: !$slots.title ? mappedHorizontalAlign : undefined }"
      >
        <component
          :is="getMdcTag(titleTag)"
          v-if="!!$slots.title"
          class="section-title"
          data-testid="section-title"
          :style="{
            ...titleMapping
          }"
        >
          <MDCSlot
            name="title"
            unwrap="p"
          />
        </component>

        <slot name="default" />
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { KUI_BREAKPOINT_LAPTOP, KUI_FONT_SIZE_70, KUI_LINE_HEIGHT_70, KUI_SPACE_0, KUI_SPACE_70 } from '@kong/design-tokens'
import type { PageSectionProps, PageSectionTitle, BackgroundImage } from '#imports'

interface MappedBackgroundImage extends BackgroundImage {
  background?: string
}

const {
  tag = 'section',
  title = undefined,
  fullWidth = false,
  maxWidth = KUI_BREAKPOINT_LAPTOP,
  borderRadius = null,
  margin = KUI_SPACE_0,
  padding = KUI_SPACE_70,
  verticalAlign = 'top',
  horizontalAlign = '',
  color = null,
  backgroundColor = '',
  backgroundImage = undefined,
  textAlign = 'left',
  border = null,
  styles = '',
} = defineProps<PageSectionProps>()

// Inject any custom `styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), useAttrs().id as string)

const backgroundImageMapping = computed((): Omit<MappedBackgroundImage, 'url' | 'backgroundOverlay'> => {
  // Since we're binding directly to `style`, we need to convert potential nested
  // properties that may be defined as kebab-case in MDC to camelCase in order
  // to properly bind them to the style attribute.

  const bgImage = convertKebabCasePropertiesToCamelCase(backgroundImage)
  // Determine if the bgImage.url prop is null or undefined
  const bgImageUrl = [undefined, null, 'undefined', 'null'].includes(bgImage?.url) ? undefined : bgImage?.url

  const backgroundOptions: Omit<MappedBackgroundImage, 'url' | 'backgroundOverlay'> = {
    backgroundOrigin: bgImage?.backgroundOrigin || 'border-box',
    backgroundPosition: bgImage?.backgroundPosition || 'center',
    backgroundRepeat: bgImage?.backgroundRepeat || 'no-repeat',
    backgroundSize: bgImage?.backgroundSize || 'auto',
  }

  if (bgImage && bgImage?.backgroundOverlay) {
    // Add background key in case if backgroundOverlay is set
    return {
      background: `linear-gradient(${bgImage.backgroundOverlay}, ${bgImage.backgroundOverlay})${bgImageUrl ? `, url('${bgImageUrl}')` : ''}${backgroundColor ? `, ${backgroundColor}` : ''}`,
      ...backgroundOptions,
    }
  } else if (bgImage) {
    // Add backgroundImage key if there's no backgroundOverlay
    return {
      background: bgImageUrl ? `url('${bgImageUrl}')${backgroundColor ? `, ${backgroundColor}` : ''}` : undefined,
      ...backgroundOptions,
    }
  }

  return {}
})

// Map values to their flex value
const mappedVerticalAlign = computed((): string => {
  switch (verticalAlign) {
    case 'top':
      return 'start'
    case 'bottom':
      return 'end'
    default:
      return verticalAlign
  }
})

// Map values to their flex value
const mappedHorizontalAlign = computed((): string => {
  switch (horizontalAlign) {
    case 'left':
      return 'start'
    case 'right':
      return 'end'
    default:
      return horizontalAlign
  }
})

const titleMapping = computed((): PageSectionTitle => {
  const titleText = convertKebabCasePropertiesToCamelCase(title)
  return {
    fontSize: titleText?.fontSize || KUI_FONT_SIZE_70,
    fontWeight: titleText?.fontWeight || '700',
    lineHeight: titleText?.lineHeight || KUI_LINE_HEIGHT_70,
    padding: titleText?.padding || undefined,
    margin: titleText?.margin || undefined,
    textAlign: titleText?.textAlign || 'center',
    color: titleText?.color || 'null',
  }
})

const titleTag = computed((): string => title?.tag || 'h2')
</script>

<style lang="scss" scoped>
.page-section {
  background-color: var(--kui-color-background, $kui-color-background);
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  margin: v-bind('margin');
  width: 100%;

  &.full-width {
    :deep(.page-section.page-hero-section) {
      padding: var(--kui-space-0, $kui-space-0) !important;
    }
  }
}

.section-container {
  margin: var(--kui-space-0, $kui-space-0) var(--kui-space-auto, $kui-space-auto);
  min-width: 0; // Important: By explicitly setting min-width: 0; on the flex item, we can override the default behavior and allow the element to shrink beyond its automatic minimum size.
  width: 100%;
}

.section-content {
  margin: var(--kui-space-0, $kui-space-0) var(--kui-space-auto, $kui-space-auto);
  width: 100%;

  :deep() {
    h1, h2, h3, h4, h5, h6 {
      color: inherit;

      > a {
        color: inherit;
      }
    }
  }
}
</style>
